import { Injectable } from '@angular/core';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { Resource } from '@opentelemetry/resources';
import {
	WebTracerProvider,
	BatchSpanProcessor,
} from '@opentelemetry/sdk-trace-web';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { W3CTraceContextPropagator } from '@opentelemetry/core';
import { OTLPTraceExporter } from '@nodeModules/@opentelemetry/exporter-trace-otlp-http';
import { AppConfigService } from '../appConfig/appConfigService';
import { TimberService } from '@logging/timber.service';
@Injectable({
	providedIn: 'root'
})
export class AppTelemetryService {
	constructor(private appConfigService: AppConfigService, private timberService: TimberService) {}

	async initialize(): Promise<void> {
		try {
			const tracerConfig = {
				resource: new Resource({
					'service.name': this.appConfigService.appSettings?.OpenTelemetryConfig?.Appname,
					'service.version': '1.0.0',
				}),
				spanProcessor:
					new BatchSpanProcessor(
						new OTLPTraceExporter({
							url: this.appConfigService.appSettings?.OpenTelemetryConfig?.Endpoint
						})),
				instrumentations: [
					new XMLHttpRequestInstrumentation({
						propagateTraceHeaderCorsUrls: [/./],
					}),
					new FetchInstrumentation({
						propagateTraceHeaderCorsUrls: [/./],
					}),
					new UserInteractionInstrumentation()
				],
			};
			const provider = new WebTracerProvider({
				resource: tracerConfig.resource,
			});

			provider.addSpanProcessor(tracerConfig.spanProcessor);

			provider.register({
				contextManager: new ZoneContextManager(),
				propagator: new W3CTraceContextPropagator(),
			});
			registerInstrumentations({
				instrumentations: tracerConfig.instrumentations as any,
			});
		} catch (error) {
			this.timberService.error('Error initializing telemetry', {
				error,
				module: 'AppTelemetryService'
			});
		}

	}
}
